import { categoryData } from '@static/approachData';
import styled from 'styled-components';
import hexToRGBA from '@utils/hexToRGBA';
import { useTranslation } from 'react-i18next';

const Category = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <FlexContainer>
        {categoryData.first.map((category) => (
          <CategorySourceContainer key={category.title}>
            <span className="title">{t(category.title)}</span>
            <CategorySourceContent>
              {category.data.map((item) => (
                <IconItem key={item.text}>
                  <img src={`images/approach/category/${item.img}`} />
                  <span>{t(item.text)}</span>
                </IconItem>
              ))}
            </CategorySourceContent>
          </CategorySourceContainer>
        ))}
      </FlexContainer>
      <Arrow />
      <CategoryOutputContainer $isOrange={true}>
        <span className="title">{t(categoryData.second.title)}</span>
        <CategoryOutputContent>
          {categoryData.second.data.map((item) => {
            const hasNewLine = t(item.text).includes('\n');
            return (
              <IconItem key={item.text} className="col" $isWhite>
                <img src={`images/approach/category/${item.img}`} />
                <span className={hasNewLine ? 'pre-line' : ''}>{t(item.text)}</span>
              </IconItem>
            );
          })}
        </CategoryOutputContent>
      </CategoryOutputContainer>
    </Container>
  );
};

export default Category;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  margin: 100px auto auto auto;

  svg {
    width: 100%;
    max-width: 1060px;
  }

  ${({ theme }) =>
    theme.media.tablet(`
      margin-top: 50px;
  `)}

  ${({ theme }) =>
    theme.media.mobile(`
      margin-top: 50px;
  `)}
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 50px;
  width: 100%;

  ${({ theme }) =>
    theme.media.tablet(`
      flex-direction: column;
      gap: 20px;
  `)}

  ${({ theme }) =>
    theme.media.mobile(`
      flex-direction: column;
      gap: 20px;
  `)}
`;

const CategorySourceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 320px;
  padding: 40px 20px;
  border-radius: 10px;
  background: ${({ theme }) => hexToRGBA(theme.newColors.dark2, 0.5)};
  color: ${({ theme }) => theme.colors.memberText};

  span.title {
    font-size: 25px;
    font-variation-settings: 'wght' 700;
    line-height: 30px;
    letter-spacing: -0.5px;
    text-align: center;
  }

  &:last-child > div {
    gap: 50px;
  }

  ${({ theme }) =>
    theme.media.tablet(`
      width: 100%;
  `)}

  ${({ theme }) =>
    theme.media.mobile(`
      width: 100%;
      padding-inline: 30px;

      &:nth-child(2) > div {
        gap: 40px 35px;
      }

      &:last-child > div {
        row-gap: 35px;
      }

      span.title {
        font-size: 20px;
        letter-spacing: -0.4px;
      }
  `)}
`;

const CategoryOutputContainer = styled.div<{ $isOrange?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  padding: 40px 30px;
  border-radius: 10px;
  background: ${({ theme, $isOrange }) =>
    $isOrange ? 'linear-gradient(135deg, #FFAB5E 0%, #FF8718 77.23%);' : hexToRGBA(theme.newColors.dark2, 0.5)};
  color: ${({ theme, $isOrange }) => ($isOrange ? theme.colors.white : theme.colors.memberText)};

  span.title {
    font-size: 25px;
    font-variation-settings: 'wght' 700;
    line-height: 30px;
    letter-spacing: -0.5px;
  }

  ${({ theme }) =>
    theme.media.tablet(`
      width: 100%;
  `)}

  ${({ theme }) =>
    theme.media.mobile(`
      width: 100%;
      padding-inline: 29px;

      span.title {
        font-size: 20px;
        letter-spacing: -0.4px;
      }
  `)}
`;

const CategorySourceContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  align-self: stretch;
  gap: 20px;

  ${({ theme }) =>
    theme.media.tablet(`
      width: 100%;
      justify-content: center;
      align-items: center;
      align-content: center;
      align-self: stretch;
      gap: 30px;
  `)}

  ${({ theme }) =>
    theme.media.mobile(`
      justify-content: center;
      align-items: center;
      align-content: center;
      align-self: stretch;
      row-gap: 30px;
  `)}
`;

const CategoryOutputContent = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  gap: 37px;

  ${({ theme }) =>
    theme.media.tablet(`
      justify-content: center;
      gap: 35px;
  `)}

  ${({ theme }) =>
    theme.media.mobile(`
      justify-content: center;
      flex-wrap: wrap;
  `)}
`;

const IconItem = styled.div<{ $isWhite?: boolean }>`
  display: flex;
  width: 80px;
  height: 90px;
  text-align: center;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  justify-self: center;
  gap: 10px;
  font-size: 20px;
  font-variation-settings: 'wght' 600;
  letter-spacing: -0.4px;
  white-space: nowrap;
  color: ${({ theme, $isWhite }) => ($isWhite ? theme.colors.white : theme.newColors.grey1)};

  .pre-line {
    white-space: pre-line;
  }

  ${({ theme }) =>
    theme.media.mobile(`
      width: 70px;
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px; 
      letter-spacing: -0.36px;
  `)}
`;

const Arrow = styled.div`
  width: 100%;
  height: 80px;
  background-image: url('icons/category_arrow_icon.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  ${({ theme }) =>
    theme.media.tablet(`
      background-image: url('icons/category_arrow_icon_tablet.svg');
      width: 100%;
      height: 80px;
  `)}

  ${({ theme }) =>
    theme.media.mobile(`
      background-image: url('icons/category_arrow_icon_mobile.svg');
      width: 100%;
      height: 80px;
  `)}
`;
