import hexToRGBA from '@utils/hexToRGBA';
import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '@assets/icons/close_icon_15.svg';
import LocationTotalCarbon from '@components/map/locationPanel/LocationTotalCarbon';
import { Position } from '@turf/helpers';
import { Popup } from 'react-map-gl';
import { TCarbonSourceDataType, TPopupCarbonDataType } from 'interfaces/mapInterface';
import { getCarbonLegendType } from '@utils/carbonData';
import LocationCarbonCategory from './LocationCarbonCategory';
import { getLocationName } from '@utils/getLocationName';
import i18n from '@utils/i18n';

type TProps = {
  coordinate: Position;
  data: TPopupCarbonDataType;
  onClose: () => void;
};

const LocationPanel = ({ coordinate, data, onClose }: TProps) => {
  const { id, ctp_cd, emd_cd, sig_cd, total, ...rest } = data;
  const activeLocale = i18n.language === 'ko' ? 'ko' : 'en';

  return (
    <CustomPopup
      latitude={coordinate[1]}
      longitude={coordinate[0]}
      onClose={onClose}
      closeButton={false}
      closeOnClick={false}
      anchor="left"
      offset={20}
    >
      <InnerContainer>
        <Header>
          <Title>{getLocationName([ctp_cd, sig_cd, emd_cd], 2, activeLocale)}</Title>
          <CloseButton onClick={onClose}>
            <CloseIcon />
          </CloseButton>
        </Header>
        <Content>
          <LocationTotalCarbon carbonAmount={data.total} carbonType={getCarbonLegendType(Object.keys(rest) as TCarbonSourceDataType[])} />
          <Hr />
          <LocationCarbonCategory categoryList={{ id, ...rest }} />
        </Content>
      </InnerContainer>
    </CustomPopup>
  );
};

export default LocationPanel;

const CustomPopup = styled(Popup)`
  z-index: 2;

  .mapboxgl-popup-tip {
    border: none;
  }

  .mapboxgl-popup-content {
    background: transparent;
    border-radius: 5px;
    padding: 0px;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  width: 280px;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  border-radius: 5px;
  background-color: ${({ theme }) => hexToRGBA(theme.newColors.dark3, 0.7)};
  box-shadow: 2px 5px 10px 0px ${({ theme }) => hexToRGBA(theme.newColors.black, 0.3)};
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  padding: 15px;

  ${({ theme }) =>
    theme.media.mobile(`
      height: 300px;
      padding-right: 5px;
  `)}
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ${({ theme }) =>
    theme.media.mobile(`
      padding-right: 5px;
  `)}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  gap: 20px;
  padding-right: 5px;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    height: 30%;
    background-color: ${({ theme }) => theme.newColors.grey1};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
`;

const Title = styled.span`
  font-size: 18px;
  font-variation-settings: 'wght' 600;
  line-height: 22px;
`;

const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
`;

const Hr = styled.hr`
  width: 100%;
  height: 1px;
  min-height: 1px;
  border: none;
  background-color: ${({ theme }) => hexToRGBA(theme.newColors.grey1, 0.5)};
`;
