import { AbsorptionData, EmissionData } from '@static/mapData';
import hexToRGBA from '@utils/hexToRGBA';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const convertNumber = (val: number) => {
  const abs = Math.abs(val);
  if (abs === 0) {
    return '0';
  } else if (abs < 0.001 && abs > 0) {
    return `> 0.001`;
  } else {
    return abs.toLocaleString('ko-KR');
  }
};

type TProps = {
  categoryList: { ei?: number; mic?: number; f?: number; rt?: number; os?: number; nep?: number; id: number };
};

const LocationCarbonCategory = ({ categoryList }: TProps) => {
  const { id, nep, ...rest } = categoryList;
  const { t } = useTranslation();

  return (
    <>
      {Object.keys(rest).length > 0 && (
        <Container>
          <NameTag>{t('common.emission')}</NameTag>
          <CategoryList>
            {Object.keys(rest).map((category) => (
              <li key={category}>
                <span>{t(EmissionData[category].title)}</span>
                <CarbonAmount>
                  <span className="number">{rest[category] === 0 ? rest[category] : rest[category].toLocaleString('ko-KR')}</span>
                </CarbonAmount>
              </li>
            ))}
          </CategoryList>
        </Container>
      )}
      {nep !== undefined && (
        <Container>
          <NameTag>{t('common.absorption')}</NameTag>
          <CategoryList>
            <li>
              <span>{`${t(AbsorptionData['nep'].title)} ${
                nep > 0 ? `(${t('common.absorptionTag')})` : `(${t('common.emissionTag')})`
              }`}</span>
              <CarbonAmount>
                <span className="number">{convertNumber(nep)}</span>
              </CarbonAmount>
            </li>
          </CategoryList>
        </Container>
      )}
    </>
  );
};

export default LocationCarbonCategory;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const NameTag = styled.span`
  width: fit-content;
  padding: 5px 10px;
  background-color: ${({ theme }) => hexToRGBA(theme.newColors.grey1, 0.5)};
  border-radius: 3px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.32px;
`;

const CategoryList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 10px;

  li {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.32px;
  }
`;

const CarbonAmount = styled.div`
  display: flex;
  gap: 10px;

  span.number {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.32px;
  }
`;
