import { CarbonLegend } from '@static/mapData';
import i18n from '@utils/i18n';
import { TCarbonType } from 'interfaces/mapInterface';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const getFormattedNumber = (num: number) => {
  const threshold = 1e-6;
  const digits = 8;

  if (num === 0) {
    return '0';
  } else if (Math.abs(num) < threshold) {
    return Math.abs(num).toExponential(digits);
  } else {
    const decimalPlaces = num.toString().split('.')[1]?.length || 0;
    return decimalPlaces > digits ? Math.abs(parseFloat(num.toFixed(digits))) : Math.abs(num);
  }
};

type TProps = {
  carbonAmount: number;
  carbonType: TCarbonType;
};

const LocationTotalCarbon = ({ carbonAmount, carbonType }: TProps) => {
  const { t } = useTranslation();
  const activeLocale = i18n.language;

  console.log(activeLocale);
  return (
    <Container>
      <CarbonHeader>
        <span className="title">
          {t(CarbonLegend[carbonType].ko)}
          {carbonType === 'absorption' && (carbonAmount > 0 ? `(${t('common.absorptionTag')})` : `(${t('common.emissionTag')})`)}
        </span>
        <span className="unit">{activeLocale === 'ko' && `${t('common.unit')}: `}tonCO2eq</span>
      </CarbonHeader>
      <span className="number">{getFormattedNumber(carbonAmount)}</span>
    </Container>
  );
};

export default LocationTotalCarbon;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;

  span.number {
    font-size: 25px;
    font-weight: 800;
    line-height: 31px;
    letter-spacing: -0.5px;
    color: ${({ theme }) => theme.newColors.primary};
  }
`;

const CarbonHeader = styled.div`
  display: flex;
  justify-content: space-between;

  span.title {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.32px;
  }

  span.unit {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.28px;
    color: ${({ theme }) => theme.newColors.grey2};
  }
`;
