import useWidthType from '@hooks/useWidthType';
import { getLocationName } from '@utils/getLocationName';
import hexToRGBA from '@utils/hexToRGBA';
import i18n from '@utils/i18n';
import s from '@utils/i18nextScan';
import { useTopRankCarbonQuery } from 'queries/useTopRankCarbonQuery';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useDashboardStore from 'stores/dashboardStore';
import styled from 'styled-components';

const TABLE_HEADER = [s('dashboard.carbonRankingResult-tableHeader01'), s('dashboard.carbonRankingResult-tableHeader02')];

type TCarbonRankingData = {
  location: string;
  carbonAmount: number;
  cityRatio?: number;
  nationalRatio?: number;
};

const CarbonRankingResult = () => {
  const widthType = useWidthType();
  const [headers, setHeaders] = useState<string[]>(TABLE_HEADER);
  const [formattedData, setFormattedData] = useState<TCarbonRankingData[]>();
  const { carbonRanking } = useDashboardStore();
  const { data } = useTopRankCarbonQuery({ dataType: carbonRanking.dataType, location: carbonRanking.location }, !!carbonRanking);
  const { t } = useTranslation();
  const activeLocale = i18n.language === 'ko' ? 'ko' : 'en';

  useEffect(() => {
    if (!data) return;
    setHeaders(
      carbonRanking.location.sig_cd
        ? TABLE_HEADER.concat([s('dashboard.carbonRankingResult-tableHeader03'), s('dashboard.carbonRankingResult-tableHeader04')])
        : TABLE_HEADER.concat([s('dashboard.carbonRankingResult-tableHeader04'), s('dashboard.carbonRankingResult-tableHeader05')]),
    );
    setFormattedData(
      carbonRanking.location.sig_cd
        ? data.data.map((item) => ({
            location: getLocationName([carbonRanking.location.ctp_cd, carbonRanking.location.sig_cd, item.emd_cd], 2, activeLocale),
            carbonAmount: item.total,
            cityRatio: data.total.sig_cd ? (item.total / data.total.sig_cd) * 100 : undefined,
            nationalRatio: (item.total / data.total.ctp_cd) * 100,
          }))
        : data.data.map((item) => ({
            location: getLocationName([carbonRanking.location.ctp_cd, item.sig_cd], 1, activeLocale),
            carbonAmount: item.total,
            cityRatio: (item.total / data.total.ctp_cd) * 100,
            nationalRatio: (item.total / data.total.ctp_cd) * 100,
          })),
    );
  }, [data]);

  return (
    <Container>
      {widthType !== 'mobile' ? (
        <TableContainer>
          {headers.map((header) => (
            <TableHeader key={header}>{t(header)}</TableHeader>
          ))}
          {formattedData &&
            formattedData.map((item, idx) => (
              <TableRow key={item.location}>
                <TableCell>
                  <span className="index">{String(idx + 1).padStart(2, '0')}</span>
                  <span>{item.location}</span>
                </TableCell>
                <TableCell>
                  <span>{item.carbonAmount.toLocaleString('ko-KR', { minimumFractionDigits: 2 })}</span>
                  <span className="unit">tonCO2eq</span>
                </TableCell>
                <TableCell>{item.cityRatio ? item.cityRatio.toLocaleString('ko-KR') : '-'} %</TableCell>
                <TableCell>{item.nationalRatio ? item.nationalRatio.toLocaleString('ko-KR') : '-'} %</TableCell>
              </TableRow>
            ))}
        </TableContainer>
      ) : (
        <FlexContainer>
          {formattedData &&
            formattedData.map((item, idx) => (
              <FlexItem key={item.location}>
                <FlexRow className="location">
                  <span className="index">{String(idx + 1).padStart(2, '0')}</span>
                  <span>{item.location}</span>
                </FlexRow>
                <FlexRow>
                  <span className="header">{t(headers[1])}</span>
                  <span>{item.carbonAmount.toLocaleString('ko-KR', { minimumFractionDigits: 2 })}</span>
                  <span className="unit">tonCO2eq</span>
                </FlexRow>
                <FlexRow>
                  <span className="header">{t(headers[2])}</span>
                  <span>{item.cityRatio ? item.cityRatio.toLocaleString('ko-KR') : '-'} %</span>
                </FlexRow>
                <FlexRow>
                  <span className="header">{t(headers[3])}</span>
                  <span>{item.nationalRatio ? item.nationalRatio.toLocaleString('ko-KR') : '-'} %</span>
                </FlexRow>
              </FlexItem>
            ))}
        </FlexContainer>
      )}
    </Container>
  );
};

export default CarbonRankingResult;

const Container = styled.div`
  width: 100%;
  min-width: fit-content;
  background-color: ${({ theme }) => hexToRGBA(theme.newColors.dark2, 0.5)};
  border-radius: 8px;
`;

const TableContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(200px, auto) 200px repeat(2, 120px);
  padding: 20px 0px;

  ${({ theme }) =>
    theme.media.tablet(`
      grid-template-columns: minmax(200px, auto) 200px repeat(2, 110px);
  `)}

  ${({ theme }) =>
    theme.media.mobile(`
      display: none;
  `)}
`;

const TableHeader = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.32px;
  color: ${({ theme }) => theme.newColors.grey2};

  padding-bottom: 20px;
  border-bottom: 1px solid ${({ theme }) => hexToRGBA(theme.newColors.grey1, 0.3)};

  &:nth-child(1) {
    margin-left: 20px;
  }

  &:nth-child(4) {
    margin-right: 20px;
  }

  &:nth-child(2),
  &:nth-child(3),
  &:nth-child(4) {
    text-align: right;
  }
`;

const TableCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;

  font-size: 16px;
  font-weight: 400;
  line-height: 20px; /* 125% */
  letter-spacing: -0.32px;

  margin-top: 20px;

  span.index {
    margin-right: 20px;
  }

  span.unit {
    color: ${({ theme }) => theme.newColors.grey1};
    margin-left: 10px;
  }

  &:nth-child(1) {
    margin-left: 20px;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -0.4px;

    justify-content: start;
  }

  &:nth-child(4) {
    margin-right: 20px;
  }

  &:nth-child(2),
  &:nth-child(3),
  &:nth-child(4) {
    text-align: right;
  }
`;

const TableRow = styled.div`
  display: contents;
`;

const FlexContainer = styled.div`
  padding: 20px;
`;

const FlexItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  & + & {
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid ${({ theme }) => hexToRGBA(theme.newColors.grey1, 0.3)};
  }
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;

  span.header {
    width: 90px;
    margin-right: 20px;

    font-size: 16px;
    font-weight: 600;
    line-height: 20px; /* 125% */
    letter-spacing: -0.32px;
  }

  span.index {
    margin-right: 20px;
  }

  span.unit {
    color: ${({ theme }) => theme.newColors.grey1};
    margin-left: 10px;
  }

  &.location {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px; /* 150% */
    letter-spacing: -0.4px;
  }
`;
